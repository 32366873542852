.upper-menu{
    display: flex;
    flex-direction: row;
    margin-top: 12px;
    justify-content: space-between;
}


.toogle-button{
    border: 1px solid #000000;
    background-color: rgba(7, 70, 165, 1);
    box-shadow: 3px 3px 0px 0px #000000;
    font-size: 18px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 181px;
    color: white;
    border-radius: 8px 8px 8px 8px;
}

.toogle-button-active{
    border: 1px solid #000000;
    background: rgba(5, 50, 117, 1);
    box-shadow: 3px 3px 0px 0px #000000;
    font-size: 18px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 181px;
    color: white;
    border-radius: 8px 8px 8px 8px;
}

.toogle-button:first-child{
    margin-left: 15px;
}

.toogle-button:last-child{
    margin-right: 15px;
}


.toogle-button-active:first-child{
    margin-left: 15px;
}

.toogle-button-active:last-child{
    margin-right: 15px;
}


.toogle-button_text{
    margin-top: 4px;
}