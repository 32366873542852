.card-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 14px;
    margin-top: 10px;
}

.big-card{
    width: calc(100% - 30px); 
    height: 0;
    padding-top: 48.07%;
    margin-left: 15px;
    margin-right: 15px; 
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid rgba(221, 241, 255, 1);
    box-shadow: 
        0px 0px 6px 2px #006DAB,
        3px 3px 2.6px 0px rgba(0, 0, 0, 0.25),
        inset 0px 0px 13px 0px #006DAB;
    display: flex;
    justify-content: center;
    position: relative;
    border-radius: 12px 12px 12px 12px;
}


.card-image{
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    width: 100%;
}


.little-cards-container{
    display: flex;
    flex-direction: row;
    margin-left: 15px;
    margin-right: 15px;
    gap: 22px;
}

.little-card{
    width: calc((100% - 11px) / 2 );  
    padding-top: 50%;      
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border: 1px solid rgba(221, 241, 255, 1);
    box-shadow: 
        0px 0px 6px 2px #006DAB,
        3px 3px 2.6px 0px rgba(0, 0, 0, 0.25),
        inset 0px 0px 13px 0px #006DAB;
    display: flex;
    justify-content: center;
    position: relative;
    border-radius: 12px 12px 12px 12px;
}


.play-button-big-card {
    border: 1px solid rgba(0, 0, 0, 1);
    height: 29px;
    box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 1);
    display: flex;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    width: 106px;
    color: white;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    position: absolute;
    bottom: 12px;
    overflow: hidden; /* Скрыть градиент за границами кнопки */
    
    /* Фон с анимацией градиента */
    background: 
        linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent) no-repeat, 
        rgba(7, 70, 165, 1); /* Фоновый цвет */
    background-size: 200% 100%, cover; /* Увеличили размер градиента для плавного ухода */
    background-position: -100% 0, 0 0; /* Стартовая позиция за кнопкой */
    animation: gradient-animation 4s infinite;
}

@keyframes gradient-animation {
    0% {
        background-position: -100% 0, 0 0; /* Начало за кнопкой */
    }
    100% {
        background-position: 200% 0, 0 0; /* Конец за кнопкой */
    }
}

.break-line{
    height: 40px;
}

.play-button-text-big-card{
    margin-top: 4px;
}