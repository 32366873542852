/* styles.css или другой файл стилей */
@font-face {
  font-family: 'Muller';
  src: url('./fonts/muller.ttf') format('truetype'); /* путь зависит от вашего расположения файла */
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Muller', sans-serif;
}

body::-webkit-scrollbar {
  display: none;
}